import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs';

import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';

const addAuthHeader = function (request: HttpRequest<any>, token: string, user?: string) {
  return user ? request.clone({ setHeaders: { 'x-access-token': token, 'x-auth-user-id': user } }) : request.clone({ setHeaders: { 'x-access-token': token } });
}


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private authService: NbAuthService;
  private tokenService: NbAuthJWTToken;

  constructor(private injector: Injector) {
  }

  // public getToken(): string {
  //     return localStorage.getItem('auth_app_token');
  // }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.authService = this.injector.get(NbAuthService); // get it here within intercept

    this.authService.isAuthenticated().subscribe((result) => {
      if (result) {
        // console.log('logged', result); 
      }
    });

    this.authService.getToken().subscribe((v: any) => {
      request = request.clone({setHeaders: { 'Authorization': `Bearer ${v?.token}`}});
    })

    return next.handle(request);
  }
}
