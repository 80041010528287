// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.mobiteach.ltd/dev', //DEV
  apiTimeout: 25000,
  IMAGE_MAX_SIZE: 8 * 1024 * 1024, // 8Mb
  IMAGE_MAX_WIDTH: 40000, // px
  checkUpdateInterval: 5000,

  // deprecated items
  apiProtocol: 'https:',
  apiHostname: 'api.mobiteach.ltd',
  apiPort: 7777,
  apiPrefix: '/api/v1',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
