import { Component, OnDestroy, OnInit } from '@angular/core';
import {NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService} from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import {filter, map, takeUntil} from 'rxjs/operators';
import { Subject } from 'rxjs';
import {NbAuthService, NbTokenService} from "@nebular/auth";
import {DEFAULT_THEME} from "../../styles/theme.default";
import {COSMIC_THEME} from "../../styles/theme.cosmic";
import {CORPORATE_THEME} from "../../styles/theme.corporate";
import {DARK_THEME} from "../../styles/theme.dark";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu: NbMenuItem[] = [
    //{ title: 'Profile' },
    { title: 'Log out', data: 'logout' }
  ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private nbMenuService: NbMenuService,
              private nbAuthService: NbAuthService,
              private tokenService: NbTokenService,
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    if (!this.themes.find(v => v.value === this.currentTheme)){
      this.themeService.changeTheme(DEFAULT_THEME.name)
    }

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => {
        //this.user = users.nick
        // @todo implement user preview
        this.user = {name: 'Admin Admin', picture: null }
      });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => {
        localStorage.setItem('theme', themeName)
        this.currentTheme = themeName
      });

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'header-menu'),
        map(({ item: { title, data } }) => ({title, data})),
      )
      .subscribe(data => {
        if('logout' === data.data){
          this.tokenService.clear()
          this.nbAuthService.isAuthenticatedOrRefresh()
        }
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  protected readonly environment = environment;
}
