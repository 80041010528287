import {ExtraOptions, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import {AuthGuard} from './@core/auth-guard.service';
import {NotFoundComponent} from './shared/not-found/not-found.component';

export const routes: Routes = [
  {
    path: 'apps',
    canActivate: [AuthGuard],
    data: {roles: ['admin', 'user']},
    loadChildren: () => import('./apps/apps.module')
      .then(m => m.AppsModule),
  },
  /*
  {
    path: 'shared-texts',
    canActivate: [AuthGuard],
    data: { roles: ['admin', 'user'] },
    loadChildren: () => import('./shared-texts/shared-texts.module')
      .then(m =>  m.SharedTextsModule),
  },
  */
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module')
      .then(m => m.AuthModule),
  },
  {path: '', redirectTo: 'apps', pathMatch: 'full'},
  {path: '**', component: NotFoundComponent},
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
